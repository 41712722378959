import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, Input, Upload, Table, message, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Ratings from '../DAL/Ratings'; 
import Icons from '../DAL/Icons';
import { actionData, dropdownDataFunc, columnsFunc } from './actionsDataJSON';
import { useSelector } from 'react-redux';

const { Option } = Select;

const ManageActionFields = ({
  ratingsData,
  iconsData,
  setRatingsData,
  setIconsData,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Cost');
  const [image, setImage] = useState(null);
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const form= useSelector((state) => state.form)
  const ratings = new Ratings(); 
  const icons = new Icons(); 
  const actionDataValues = actionData(ratingsData, iconsData);
  const dropdownData = dropdownDataFunc(actionDataValues);
  const [orderedData, setOrderData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const ratingsData = await ratings.getAllRatings(form.id);
      const iconsData = await icons.getAllIcons(form.id);
      setRatingsData(ratingsData);
      setIconsData(iconsData);
      setDataSource(ratingsData.filter(rating => rating.type.toLowerCase() === 'cost'));
    };
    fetchData();
  }, []);
  useEffect(()=>{
    setOrderData(dataSource.sort((a, b) => a.name.localeCompare(b.name)))
  }, [dataSource])

  const handleManage = (e) => {
    setSelectedOption(e);
    if (e.toLowerCase() === 'icon') {
      setDataSource(iconsData);
    } else {
      setDataSource(ratingsData.filter(rating => rating.type.toLowerCase() === e.toLowerCase()));
    }
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
    setImage(null);
    setItemName('');
    setDescription('');
    setEditingItem(null);
  };

  const handleAddIcon = async () => {
    try {
      await icons.createIcon({
        name: itemName,
        description: description,
        image: image,
        status: true,
        formId: form.id,
      });
      message.success('Icon added successfully!');

      const data = await icons.getAllIcons(form.id);
      setIconsData(data);
      setDataSource(data);

      handleCancel();
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const handleAddRating = async () => {
    try {
      await ratings.createRating({
        name: itemName,
        description: description,
        type: selectedOption,
        status: true,
        formId:form.id,
      });
      message.success(`${selectedOption} rating added successfully!`);

      const data = await ratings.getAllRatings(form.id);
      setRatingsData(data);
      setDataSource(data.filter(rating => rating.type.toLowerCase() === selectedOption.toLowerCase()));

      handleCancel();
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const handleAdd = () => {
    if (selectedOption === 'Icon') {
      handleAddIcon();
    } else {
      handleAddRating();
    }
  };

  const handleImageUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        if (img.width <= 100 && img.height <= 100) {
          setImage(e.target.result);
        } else {
          message.error('Image must be less than 100x100 pixels');
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const isAddButtonDisabled = () => {
    if (selectedOption === 'Icon') {
      return !image || !itemName.trim();
    }
    return !itemName.trim();
  };

  const handleEditItem = (record) => {
    setEditingItem(record);
    setItemName(record.name);
    setDescription(record.description);
    setImage(record.image || null);
    setIsAddModalVisible(true);
  };

  const handleUpdateItem = async () => {
    if (editingItem) {
      try {
        if (selectedOption === 'Icon') {
          await icons.updateIcon(editingItem.id, {
            name: itemName,
            description: description,
            image: image,
            formId: form.id,
          });
          const data = await icons.getAllIcons(form.id);
          setIconsData(data);
          setDataSource(data);
        } else {
          await ratings.updateRating(editingItem.id, {
            name: itemName,
            description: description,
            type: selectedOption,
            formId: form.id,
          });
          const data = await ratings.getAllRatings(form.id);
          setRatingsData(data);
          setDataSource(data.filter(rating => rating.type.toLowerCase() === selectedOption.toLowerCase()));
        }
        message.success(`${selectedOption} successfully updated!`);
        handleCancel();
      } catch (error) {
        console.error(error);
        message.error(error.message);
      }
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      if (selectedOption === 'Icon') {
        await icons.deleteIcon(id);
        const data = await icons.getAllIcons(form.id);
        setIconsData(data);
        setDataSource(data);
      } else {
        await ratings.deleteRating(id);
        const data = await ratings.getAllRatings(form.id);
        setRatingsData(data);
        setDataSource(data.filter(rating => rating.type.toLowerCase() === selectedOption.toLowerCase()));
      }
      message.success(`${selectedOption} successfully deleted!`);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  };

  const columns = columnsFunc(selectedOption, handleEditItem, handleDeleteItem);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Select
          placeholder="Select Option"
          style={{ width: 'calc(100% - 180px)', marginRight: '8px' }}
          onChange={handleManage}
          value={selectedOption}
        >
          {dropdownData.map((ddD) => (
            <Option key={ddD.name} value={ddD.name}>
              {ddD.name}
            </Option>
          ))}
        </Select>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsAddModalVisible(true)}
          style={{ width: '150px' }}
        >
          Add New {selectedOption}
        </Button>
      </div>
      {selectedOption && (
        <Table
          className="custom-table"
          columns={columns}
          dataSource={orderedData}
          style={{ backgroundColor: 'white' }}
          pagination={false}
        />
      )}
      <Modal
        title={editingItem ? `Edit ${selectedOption}` : `Add New ${selectedOption}`}
        visible={isAddModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical">
          {selectedOption === 'Icon' && (
            <Form.Item label="Upload Image" name="image">
              <Upload
                beforeUpload={() => false}
                showUploadList={false}
                onChange={handleImageUpload}
                accept="image/*"
              >
                <Button>Upload Image</Button>
              </Upload>
              {image && <img src={image} alt="icon" style={{ width: '100px', height: '100px' }} />}
            </Form.Item>
          )}
          <Form.Item label="Name" name="name">
            <Input value={itemName} onChange={(e) => setItemName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={editingItem ? handleUpdateItem : handleAdd}
              disabled={isAddButtonDisabled()}
            >
              {editingItem ? 'Update' : 'Add'}
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: '8px' }}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export { ManageActionFields };
