import React,{useEffect, useState} from 'react'
import {Header, HistoryLog, InstallerInformation, InspectionInformation, SiteOwnerInformation} from '.'
import {SiteRepresentative, SiteNotes, UploadedDocuments, ProjectDetails, ProjectCancelled, CancelRequesed, ProjectOwner} from '.'
import {auth} from '../Auth'
import './DetailedProjectView.css'
import {useLoggerEffect} from '../effects'

export default function DetailedProjectView({resource, match, history}) {
  const logger = useLoggerEffect()
  const projectDetailsProps = {project: resource, history, installerReadable: resource.installerReadable}
  const [allowCreateProject, setAllowCreateProject] = useState(false); // State to track if "Create a Project like this" button should be enabled
  useEffect(()=>{
    if(resource.allowInspectorToCreateProjects){
      setAllowCreateProject(resource.allowInspectorToCreateProjects)
    }
  },[])
  return (
    <div className="flex-column" style={{padding: `3rem`}}>
      <Header allowCreateProject={allowCreateProject} id={resource.id} status={resource.status} organization={resource.organization.id} />
      <div className="flex-row" style={{background: `rgba(249, 250, 251, 0.7)`, textAlign: `left`}}>
        <div className="flex-column full-width" style={{flex: 2}}>
          {resource.status === `Canceled` && <ProjectCancelled cancellations={resource.cancellations} />}
          {resource.status === `Cancel Requested` && <CancelRequesed cancellations={resource.cancellations} />}
          
          <ProjectOwner owner={(resource.organization || {}).name} />
          <InspectionInformation project={resource} />
          <div className="background-white margin-bottom-2" data-cy="siteInfoProjectDetail">
            <h3 className="padding-2" style={{borderBottom: `0.1rem solid #DFE3E8`}}>
              Site Info
            </h3>
            <SiteOwnerInformation {...resource.site} />
            <SiteRepresentative {...resource.site} />
            {auth.sunkaizenUser.type !== `installer` && <SiteNotes resource={resource} />}
          </div>
          <InstallerInformation installer={resource.installer} nabcep_ids={resource.nabcep_ids} />
          <HistoryLog projectId={match.params.id} />
          <UploadedDocuments project={resource} />
        </div>
        <div className="margin-left-1" style={{flex: 1}}>
          <ProjectDetails {...projectDetailsProps} />
        </div>
      </div>
    </div>
  )
}
