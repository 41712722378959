import {store} from '../../utilities'
import {setFailures, setTicket} from '../../action_creators'
import {history} from '../../../history'
import {makeTicket} from '../../utilities'
import DB from '../../../DAL/DB'
import {firebase} from '../../../../src/utilities'
import { auth } from '../../../Auth'
import P from '../../../Reports/ReportsList'

const tickets = new DB.Tickets()
const projects = new DB.Projects()

export default async function onOk() {
  const state = store.getState()
  const ff = state.failures

  console.log('state while creating ticket>',state)

  try {
    const ticket = await makeTicket()


    await tickets.update(ticket.id, null, {...ticket, assigneeType: ticket.watcherMap[ticket.assignee]?.type || null })
   

    try {
      await projects.updatebatch(ticket.project, null, { tickets: firebase.firestore.FieldValue.arrayUnion({ 
        id: ticket.id, 
        title: ticket.title,
        updated: firebase.firestore.Timestamp.now(), // firebase.firestore.FieldValue.serverTimestamp(),  // or firebase.firestore.timestamp.now()
        updatedBy: auth?.sunkaizenUser?.id, 
        updatedByName: auth?.sunkaizenUser?.name
      })  })
    }
    catch (Error) {
      console.log('error',Error)
    }
    

    delete ff[ticket.failure.id]
    store.dispatch(setTicket({priority: 'Normal'}))
    store.dispatch(setFailures(ff))

    if (!Object.keys(ff).length && state.meta.active === 'create') {
      history.push('/tickets')
    }
  } catch (e) {
    console.log('ticket creation failed', e)
    throw e
  }
}
