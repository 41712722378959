import React, {useEffect, useState} from 'react'
import {Select} from 'antd'
import {auth} from '../../Auth'
import {setField} from '../action_creators'
import {connect} from 'react-redux'

const stop = (state) => ({
  organizations: Object.values(state.dropdowns.organizations || {}),
  fields: state.fields,
  organization: (state.fields || {}).organization,
  project: state.projectBase,
})
const dtop = (dispatch) => ({setField: (field, value) => dispatch(setField(field, value))})

export function ProjectOwner({
  disabled = false,
  project = {},
  fields,
  organizations = [],
  organization = {},
  setField,
  allowCreateProject = false
}) {
  const [organizationsMap, setOrganizationsMap] = useState({})

  useEffect(
    function () {
      const m = {}
      for (let o of organizations) {
        if (!Array.isArray(o.roles)) continue
        if (o.roles.includes('Project Reviewer')) m[o.id] = o
      }

      setOrganizationsMap(m)
    },
    [organizations]
  )

  useEffect(
    function () {
      // hidden permission (sorry): organizations can always create internal projects
      if (!fields) return
      if (!Object.keys(organization).length) {
        setField('organization', auth.sunkaizenUser.organization)
      }
    },
    [organization, fields]
  )

  return (
    <div className="flex-column padding-2 align-start margin-bottom-1" style={{background: '#F4F6F8'}}>
      <h3>Project Owner</h3>
      <Select
        placeholder="Please select an organization that owns this project"
        value={(organization || {}).name}
        size="large"
        style={{width: '100%'}}
        disabled={allowCreateProject || disabled || !!project.status}
        onChange={(e) => {
          setField('organization', organizationsMap[e])
        }}
        data-cy="organization"
      >
        {Object.values(organizationsMap)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((organization) => (
            <Select.Option value={organization.id}>{organization.name}</Select.Option>
          ))}
      </Select>
    </div>
  )
}

const WrappedProjectOwner = connect(stop, dtop)(ProjectOwner)
export default WrappedProjectOwner
