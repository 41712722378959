import React, {useState, useEffect} from 'react'
import {auth} from '../../Auth'
import {Link} from 'react-router-dom'
import JSZip from 'jszip'
import {saveAs} from 'file-saver'
import {Icon, Button} from 'antd'
import {firebaseConfig} from '../../config'
import {checkLock} from '../../effects'
import {breakLock} from './breakLock'

// field-user and inspector are in here because the project user_index uses inspector but should use field-user
//  so when someone does get around to swapping inspector to field-user, this code should still work -- Chris
const allowedTypesForStatus = {
  Scheduled: [`field-user`, `inspector`],
  'In Progress': [`field-user`, `inspector`],
  Review: ['quality-manager', 'reviewer'],
  'Needs Corrections': [`field-user`, `inspector`],
}
const allowedUsers = [`admin`, `super-admin`, `field-user`, `quality-manager`, `client`]

export default function IntakeAndSnapshots({project, snapshots = []}) {
  const {id, site, status, client, organization, inspection } = project

  const showIntakeButtonTests = []
  const [lock, setLock] = useState(true)

  checkLock(id, setLock)
  showIntakeButtonTests.push(!!auth.sunkaizenUser)
  // TODO: Completed excluded to test with Matt
  showIntakeButtonTests.push(!['To Be Scheduled', 'Canceled', 'Requested'].includes(status))
  showIntakeButtonTests.push(allowedUsers.includes((auth.sunkaizenUser || {}).type))
  showIntakeButtonTests.push(!lock)

  //console.log('showIntakeButtonTests:', showIntakeButtonTests)

  let showIntakeButton = showIntakeButtonTests.every((v) => v)

  let intakeFormButtonDisabled = false
  if (['client'].includes((auth.sunkaizenUser || {}).type)) {
    intakeFormButtonDisabled = auth.sunkaizenUser?.organization?.id !== organization?.id &&  auth.sunkaizenUser?.organization?.id !== inspection?.inspector?.organization?.id
  }


  async function downloadSnapshots() {
    let pp = snapshots.map(({fullPath}) => {
      const corrected = fullPath.replace(/\//g, '%2F').replace(/,/g, '%2C')
      return fetch(
        `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`
      )
    })
    const results = await Promise.all(pp)
    pp = results.map((result) => result.blob())
    const blobs = await Promise.all(pp)

    const {siteOwnerName, siteAddress} = site
    const zip = new JSZip()
    blobs.forEach((blob, i) => zip.file(snapshots[i].name, blob, {binary: true}))
    saveAs(await zip.generateAsync({type: `blob`}), `Snapshots for ${siteOwnerName}-${siteAddress}-${id}`)
  }

  return (
    <div className="padding-top-1">
      <div className="margin-1">
        {(lock && ![`installer`].includes(auth.sunkaizenUser.type)) && (
          <Button
            onClick={async () => {
              await breakLock(id)
              setLock(false)
            }}
          >
            Break Lock
          </Button>
        )}
      </div>
      {showIntakeButton && (
        <div className="full-width">
          <Link disabled={intakeFormButtonDisabled}  to={`/intake/${id}`} className="intake-link">
            <Icon className="padding-right-1" type="file-sync" />
            Intake Form
          </Link>
          {snapshots.length > 0 && (
            <div className="snapshot-box">
              <h4>Intake Snapshots</h4>
              <div className="flex-column">
                {snapshots.slice(0, 5).map((snapshot) => {
                  const {fullPath} = snapshot
                  const corrected = fullPath.replace(/\//g, '%2F').replace(/,/g, '%2C')

                  return (
                    <a
                      href={`https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${corrected}?alt=media`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {snapshot.created.toDate().toISOString()}
                    </a>
                  )
                })}
                {snapshots.length > 5 && <div>and {snapshots.length - 5} more...</div>}
              </div>
              <Button className="margin-1" onClick={downloadSnapshots}>
                Download All
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
