import React, {useState, useEffect} from 'react'
import {Tabs} from 'antd'
import Settings from './FormSettings'
import Logo from './Logo'
import DraggableTabs from './DraggableTabs'
import FieldsWrapper from './FieldsPanel'
import {ActionsReq} from './ActionsReq'
import {DEFAULT_TAB_ID} from './actionsDataJSON'
import ReportTemplateText from './components/ReportTemplateText';
import AccessSettings from './components/AccessSettings';
import {ManageActionFields} from './ManageActionFields'
import Ratings from '../DAL/Ratings'; // Importing Ratings for fetching ratings data
import Icons from '../DAL/Icons'; // Importing Icons for fetching icons data

export default function FormEdit() {
  const [defaultTabId, setDefaultTabId] = useState(DEFAULT_TAB_ID);
  const [title, setTitle] = useState('');
  const { TabPane } = Tabs;
  const [ratingsData, setRatingsData] = useState([]);
  const [iconsData, setIconsData] = useState([]); 
  const handleTabChange = (tabId) => {
    setDefaultTabId(tabId);
  };

  const handleTitleUpdate = (title) => {
    setTitle(title);
  };
  const ratings = new Ratings(); // Creating an instance of Ratings
  const icons = new Icons(); // Creating an instance of Icons

  useEffect(() => {
    const fetchData = async () => {
      const ratingsData = await ratings.getAllRatings(); // Fetching all ratings
      const iconsData = await icons.getAllIcons(); // Fetching all icons
      setRatingsData(ratingsData); // Setting fetched ratings data to state
      setIconsData(iconsData); // Setting fetched icons data to state
    };
    fetchData();
  }, []);

  return (
    <div className="padding-2 flex-column">
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={title} key="1">
          <Settings handleTitleUpdate={handleTitleUpdate} />
          <Logo />
          <DraggableTabs />
          <FieldsWrapper />
        </TabPane>
        <TabPane tab="Actions" key="2">
          <ActionsReq
                  ratingsData ={ratingsData}
                  iconsData={iconsData}
                  setRatingsData={setRatingsData}
                  setIconsData={setIconsData}
          />
        </TabPane>
        <TabPane tab="Ratings and Icons" key="3">
        <ManageActionFields 
        ratingsData ={ratingsData}
        iconsData={iconsData}
        setRatingsData={setRatingsData}
        setIconsData={setIconsData}
        />
        </TabPane>
        <TabPane tab="Report Template" key="4">
          <ReportTemplateText />
        </TabPane>
        <TabPane tab="Access" key="5">
          <AccessSettings />
        </TabPane>
      </Tabs>
    </div>
  );
}
