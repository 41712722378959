import BaseModel from './BaseModel';
import { db, firebase } from '../utilities'; 

interface Rating {
  id?: string;
  name: string;
  description: string;
  type: string;
  formId: string;
}

class Ratings extends BaseModel {
  constructor() {
    super(db.collection('ratings'));
  }

  async getAllRatings(formId: string | undefined): Promise<Rating[]> {
    try {
      if (!formId) {
        console.log("formId is undefined. Skipping query.");
        return [];
      }
      const snapshot = await this.baseQuery.where('status', '==', true).where('formId', '==', formId).get();
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map((doc: { id: any; data: () => any; }) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error( error);
      return [];
    }
  }

  async createRating(rating: Rating): Promise<void> {
    try {
      const snapshot = await this.baseQuery
        .where('type', '==', rating.type)
        .where('formId', '==', rating.formId )
        .where('name', '==', rating.name)
        .get();
      if (!snapshot.empty) {
        throw new Error(`${rating.type} name must be unique`);
      }
      await this.baseQuery.add(rating);
    } catch (error) {
      console.error( error);
      throw error;
    }
  }

  async updateRating(id: string, rating: Rating): Promise<void> {
    try {
      const snapshot = await this.baseQuery
        .where('type', '==', rating.type)
        .where('name', '==', rating.name)
        .where('formId', '==', rating.formId)
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
        .get();
      if (!snapshot.empty) {
        throw new Error(`${rating.type} name must be unique`);
      }
      await this.baseQuery.doc(id).set(rating, { merge: true });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteRating(id: string): Promise<void> {
    try {
      await this.baseQuery.doc(id).update({ status: false });
    } catch (error) {
      console.error( error);
      throw error;
    }
  }
}

export default Ratings;
