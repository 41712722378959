import React, { useEffect, useState, useReducer } from "react";
import { useParams, useHistory } from "react-router-dom";
import CreateClientUser from "./UserBuilder/UserInformation/CreateClientUser";
import CreateProjectClient from "./ProjectBuilder/ProjectCreator/CreateProjectClient";
import SpecialAccess from "./DAL/SpecialAccess";
import { usersOne, validators } from "./UserBuilder/utilities";
import { validateField } from "./validators";
import DB from "./DAL/DB";
import { loggerEffect } from "./effects";
import { Button, message } from 'antd';
import { db } from "./utilities";
import { firebase } from './utilities';
import { makeSiteAddress } from './ProjectBuilder/utilities';
import {setInspector} from "./ProjectBuilder/utilities";

const users = new DB.Users();
const projects = new DB.Projects();

function userReducer(state, action) {
  switch (action.type) {
    case "setField":
      return { ...state, [action.field]: action.value };
    case "clearFields":
      return { errors: {} };
    default:
      return state;
  }
}

function projectReducer(state, action) {
  switch (action.type) {
    case "setField":
      return { ...state, [action.field]: action.value };
    case "clearFields":
      return { errors: {} };
    default:
      return state;
  }
}

export default function CreateClient() {
  const siteRef = db.collection(`sites`).doc()
  const { specialurl } = useParams();
  const history = useHistory();
  
  const [userState, userDispatch] = useReducer(userReducer, { errors: {} });
  const [projectState, projectDispatch] = useReducer(projectReducer, { errors: {} });

  const [validUrl, setValidUrl] = useState(null);
  const [specialAccessData, setSpecialAccessData] = useState(null);
  const [logger, setLogger] = useState({ error: console.log, event: console.log });
  const [loading, setLoading] = useState(false);

  useEffect(loggerEffect(setLogger), []);
  useEffect(() => {
    validateSpecialUrl();
  }, [specialurl]);

  async function validateSpecialUrl() {
    try {
      const allData = await SpecialAccess.getAll();
      const foundRecord = allData.find((record) => record.specialurl === specialurl && record.status === "new");
      if (foundRecord) {
        setValidUrl(true);
        setSpecialAccessData(foundRecord);
      } else {
        setValidUrl(false);
      }
    } catch (error) {
      console.error("Error validating special URL:", error);
      setValidUrl(false);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    const requiredUserFields = ['name', 'email', 'address', 'phone'];
    const missingUserFields = requiredUserFields.filter(field => !userState[field]);
    const requiredProjectFields = ['siteOwnerName', 'siteAddress', 'siteRepEmail', 'siteRepPhone'];
    const missingProjectFields = requiredProjectFields.filter(field => !projectState[field]);

    if (missingUserFields.length > 0 || missingProjectFields.length > 0) {
      const missingFieldsMessage = `
        Please fill out all required fields
      `;

      message.error(missingFieldsMessage);
      setLoading(false);
      return; 
    }

    try {
      const userData = {
        ...userState,
        organization: specialAccessData.formdetails?.organization || undefined,
        type: 'field-user',
      };

      await usersOne(userData, logger);
      const unsubscribe = await users.subscribe(userData.id, null, async ({ data: [user] }) => {
        if (user && user.verified === "error") {
          console.log(`User creation for ${user.name} failed. There is already a user with this email address`);
        } else if (user) {
          unsubscribe();
          console.log(`Successfully created user ${user.name}`);

          await handleProjectCreation(user);
          message.success('Client Created Successfully!');
          resetFields(); 
          setLoading(false);
        } else {
          console.error("User data not available or null", user);
        }
      });
      history.push('/login');
    } catch (error) {
      console.error("Error during creation process:", error);
      setLoading(false);
      message.success('Error during creation process');

    } 
  }

  function removeUndefinedFields(data) {
    if(data instanceof firebase.firestore.FieldValue || data instanceof firebase.firestore.Timestamp){
      return data
    }
    
    if (typeof data !== 'object' || data === null) {
      return data; 
    }
    return Array.isArray(data)
    ? data.map(removeUndefinedFields) // Recursively clean arrays
    : Object.fromEntries(
        Object.entries(data)
        .filter(([_, value]) => value !== undefined) // Filter out undefined values
        .map(([key, value]) => [key, removeUndefinedFields(value)]) // Recursively clean nested objects
    );
}
  async function handleProjectCreation(user) {

    try {
      const {geocode_results = {}, latitude, longitude} = projectState
      const {siteAddress = ``, dummyAddress = ``, sameRep = false, sameAddress = false} = projectState
      const {siteOwnerName = ``, siteRepName = ``, siteRepEmail = ``, siteRepPhone = ``} = projectState
      let site = {
        geocode_results,
        siteAddress,
        sameAddress,
        siteRepName,
        siteRepEmail,
        siteRepPhone,
        sameRep,
        siteOwnerName,
        id: siteRef.id,
      }
      site = await makeSiteAddress({
        site,
        siteAddress,
        dummyAddress,
        latitude,
        longitude,
      })  
      await siteRef.set(site)

      const projectFields = {
          formRubrics: {},
          rubric: specialAccessData.rubricdetails,
          intake: {...specialAccessData.formdetails, skipReview: true},
          client: { id: specialAccessData.clientid, name: specialAccessData.clientname, qualifications: {} },
          organization: specialAccessData.formdetails?.organization || undefined,
          installer: { id: '', name: '' },
          restrict_schedule: false,
          site: site,
          allowInspectorToCreateProjects: specialAccessData.allowInspectorToCreateProjects
        };

        let returns = []
        await setInspector(projectFields, user , returns);
        const projRef = db.collection('projects').doc();
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const pp = { ...projectFields, ...{ id: projRef.id, assigned: false, created: timestamp, updated: timestamp}};
        pp.status = pp.intake.noSchedule ? `In Progress` : `To Be Scheduled`
        Object.keys(pp.rubric.questions).forEach(rec => {
          if (pp.rubric.questions[rec] && pp.rubric.questions[rec].OptionActions) {
            const quest1 = pp.rubric.questions[rec].OptionActions
            Object.keys(quest1).forEach(oAct => {
              quest1[oAct].forEach((testrec, index) => {
                if (testrec && testrec.id) {
                  const {id, conditionGroup, actionIndex, strategy} = testrec
                  pp.rubric.questions[rec].OptionActions[oAct][index] = 
                  { id, conditionGroup: conditionGroup || {}, actionIndex, strategy }
                }
              })
            })
          }
        })
    
        await projects.update(pp.id, null, {id: pp.id});
        const clean_data = removeUndefinedFields(pp);
        await db.collection('projects').doc(pp.id).update({...clean_data});        
      } catch (error) {
        console.error("Error creating project:", error);
    }
  }

  const setUserField = (field) => (value) => {
    if (field !== "geocode_results") {
      const errors = { [field]: validateField(value)(validators[field]) };
      userDispatch({ type: "setField", field: "errors", value: { ...userState.errors, ...errors } });
    }
    userDispatch({ type: "setField", field, value });
  };

  const setProjectField = (field) => (value) => {
    projectDispatch({ type: "setField", field, value });
  };

  const resetFields = () => {
    userDispatch({ type: "clearFields" });
    projectDispatch({ type: "clearFields" });
    validateSpecialUrl();
  };

  if (validUrl === null) {
    return <div>Loading...</div>;
  }

  if (!validUrl) {
    return <div>Error: Invalid or expired URL.</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <CreateClientUser fields={userState} setField={setUserField}/>
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <CreateProjectClient fields={projectState} setField={setProjectField} />
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>
        <Button onClick={() => history.goBack()} style={{ marginLeft: "10px" }}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
