import React, {useState} from 'react'
import AddAction from './AddAction'

// Actions will render by calling Add action component
const ActionsRender = ({
  actionList,
  handleDeleteAction,
  handleDeleteActionCancel,
  handleDeleteActionConfirmation,
  handleUpdateAction,
  ratingsData,
  iconsData,
  setRatingsData,
  setIconsData,

}) => {
  return (actionList.sort((a,b) => a.actionIndex-b.actionIndex) || [])
    .filter((x) => x.isDelete !== true)
    .map((rec, index) => (
      <AddAction
        key={`action-${rec.id}`}
        action={rec}
        currentIndex={rec.actionIndex}
        handleDeleteAction={(rec) => handleDeleteAction(rec)}
        handleDeleteActionCancel={(rec) => handleDeleteActionCancel(rec)}
        handleDeleteActionConfirmation={(rec) => handleDeleteActionConfirmation(rec)}
        handleUpdateAction={(rec, updatedActionObj) => handleUpdateAction(rec, updatedActionObj)}
        ratingsData ={ratingsData}
        iconsData={iconsData}
        setRatingsData={setRatingsData}
        setIconsData={setIconsData}
      />
    ))
}

export default ActionsRender
