import BaseModel from './BaseModel';
import { db, firebase } from '../utilities';

class Icons extends BaseModel {
  constructor() {
    super(db.collection('icons'));
  }

  async getAllIcons(formId: string | undefined) {
    try {
      if (!formId) {
        console.warn('formId is undefined or null. Returning empty array.');
        return [];
      }
      const snapshot = await this.baseQuery.where('status', '==', true).where('formId', '==', formId).get();
      if (snapshot.empty) {
        return [];
      }
      const icons = snapshot.docs.map((doc: { id: any; data: () => any; }) => ({ id: doc.id, ...doc.data() }));
      return icons;
    } catch (error) {
      console.log('Error fetching icons:', error);
      throw error;
    }
  }

  async createIcon(icon: { name: string; description?: string; image?: string;  formId: string;  }) {
    try {
      const snapshot = await this.baseQuery.where('formId', '==', icon.formId).where('name', '==', icon.name).get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.add(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateIcon(id: string, icon: { name: string; description?: string; image?: string; formId?: string }) {
    try {
      const snapshot = await this.baseQuery
        .where('name', '==', icon.name)
        .where('formId', '==', icon.formId)
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
        .get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.doc(id).update(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteIcon(id: string) {
    try {
      await this.baseQuery.doc(id).update({ status: false });
    } catch (error) {
      console.error( error);
      throw error;
    }
  }
}

export default Icons;
