import React, { useState, useEffect } from "react";
import { Checkbox, Input, Select, Button, Modal, Table, Popconfirm, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSpecialAccess,
  fetchRubricsByFormId,
  fetchOrganizationsByUserId,
  fetchSpecialAccessRecords,
  disableSpecialAccessRecord,
} from "../action_creators";
import { auth } from "../../Auth";
import { generateProjectShell } from "../../ProjectBuilder/utilities";

const { Option } = Select;

const AccessSettings = () => {
  const dispatch = useDispatch();
  const rubrics = useSelector((state) => state.rubrics.rubrics || []);
  const clients = useSelector((state) => state.clients.clients || []);
  const form = useSelector((state) => state.form || []);
  const formId = useSelector((state) => state.form.id);
  const specialAccessRecords = useSelector((state) => state.specialAccess.data || []);
  
  const [allowSpecialRegistration, setAllowSpecialRegistration] = useState(false);
  const [allowInspectorToCreateProjects, setAllowInspectorToCreateProjects] = useState(false); // New state for the checkbox
  const [specialUrl, setSpecialUrl] = useState("");
  const [selectedRubric, setSelectedRubric] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (formId) {
      dispatch(fetchRubricsByFormId(formId));
      dispatch(fetchOrganizationsByUserId());
      dispatch(fetchSpecialAccessRecords(formId));
    }
  }, [dispatch, formId]);

  useEffect(() => {
    if (specialAccessRecords.length > 0) {
      setAllowSpecialRegistration(true);
    }
  }, [specialAccessRecords]);

  useEffect(() => {
    if (allowSpecialRegistration && formId) {
      setSpecialUrl(`${generateUniqueSuffix()}`);
    } else {
      setSpecialUrl("");
    }
  }, [allowSpecialRegistration, formId]);

  const generateUniqueSuffix = () => {
    const timestamp = Date.now();
    const randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    return `${timestamp}${randomChar}`;
  };

  const handleAllowSpecialRegistrationChange = (e) => {
    setAllowSpecialRegistration(e.target.checked);
  };

  const handleAllowInspectorToCreateProjectsChange = (e) => {
    setAllowInspectorToCreateProjects(e.target.checked); // Track the checkbox state
  };

  const handleRubricChange = (value) => {
    setSelectedRubric(value);
  };

  const handleClientChange = (value) => {
    setSelectedClient(value);
  };

  const handleSave = async () => {
    if (!selectedRubric || !selectedClient) {
      Modal.error({
        title: "Missing Required Fields",
        content: "Please select both a Rubric and a Client.",
      });
      return;
    }

    const rubric = rubrics.find((r) => r.id === selectedRubric);
    const client = clients.find((c) => c.id === selectedClient);
    const specialAccessData = {
      specialurl: specialUrl,
      formid: formId,
      rubricname: rubric.name,
      rubricid: rubric.id,
      formdetails: await generateProjectShell(form) || "",
      rubricdetails: rubric || "", // Assuming rubric has a details field
      clientname: client.name,
      clientid: client.id,
      clientdetails: client || "", // Assuming client has a details field
      usercreated: auth.sunkaizenUser.id,
      datecreated: new Date(),
      status: "new",
      allowInspectorToCreateProjects, // New field to save the checkbox state
    };

    try {
      const result = await dispatch(saveSpecialAccess(specialAccessData));

      if (result && result.type === "SAVE_SPECIAL_ACCESS_SUCCESS") {
        resetFields();
        dispatch(fetchSpecialAccessRecords(formId)); // Fetch updated records after saving
        setIsModalVisible(false); // Close the modal
      }
    } catch (error) {
      console.error("Error saving special access data: ", error);
    }
  };

  const resetFields = () => {
    setSpecialUrl(`${generateUniqueSuffix()}`);
    setSelectedRubric("");
    setSelectedClient("");
    setAllowInspectorToCreateProjects(false); // Reset the checkbox state
  };

  const handleDeleteRecord = async (recordId) => {
    try {
      await dispatch(disableSpecialAccessRecord(recordId));
      dispatch(fetchSpecialAccessRecords(formId)); // Fetch updated records after disabling
    } catch (error) {
      console.error("Error disabling special access record: ", error);
    }
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientname",
      key: "clientname",
    },
    {
      title: "Rubric Name",
      dataIndex: "rubricname",
      key: "rubricname",
    },
    {
      title: "Special URL",
      dataIndex: "specialurl",
      key: "specialurl",
    },
    {
      title: "Date Created",
      dataIndex: "datecreated",
      key: "datecreated",
      render: (text) => new Date(text.seconds * 1000).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status === "new" ? "Invited" : status),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to disable this record?"
          onConfirm={() => handleDeleteRecord(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <h3>Access Settings</h3>
      <Checkbox checked={allowSpecialRegistration} onChange={handleAllowSpecialRegistrationChange}>
        Allow Projects with this form to be created during special registration
      </Checkbox>
      {allowSpecialRegistration && (
        <>
          <div style={{ marginTop: "1rem" }}>
            <Tooltip title="The Special URL should be accessed as: hostname/invite/value-of-Special-URL">
              <p style={{ marginBottom: "1rem" }}>
                <em>Example: {window.location.origin}/invite/{specialUrl}</em>
              </p>
            </Tooltip>
            <Table
              columns={columns}
              dataSource={specialAccessRecords}
              rowKey="id"
              pagination={false}
              style={{
                marginBottom: "1rem",
                backgroundColor: "white",
                border: "1px solid #f0f0f0",
              }}
            />
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              Add New Record
            </Button>
            <Modal
              title="Add Special Access Record"
              visible={isModalVisible}
              onOk={handleSave}
              onCancel={() => setIsModalVisible(false)}
            >
              <div>
                <label>
                  Special URL: (<em>{window.location.origin}/invite/</em>)
                </label>
                <Input value={specialUrl} readOnly style={{ marginBottom: "1rem" }} />
                <label>Rubrics:</label>
                <Select
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleRubricChange}
                  value={selectedRubric}
                >
                  {rubrics.map((rubric) => (
                    <Option key={rubric.id} value={rubric.id}>
                      {rubric.name}
                    </Option>
                  ))}
                </Select>
                <label>Clients:</label>
                <Select
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleClientChange}
                  value={selectedClient}
                >
                  {clients.map((client) => (
                    <Option key={client.id} value={client.id}>
                      {client.name}
                    </Option>
                  ))}
                </Select>
                <Checkbox
                  checked={allowInspectorToCreateProjects}
                  onChange={handleAllowInspectorToCreateProjectsChange}
                >
                  Allow Inspector to Create Additional Projects for this Form
                </Checkbox>
              </div>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default AccessSettings;
