import React from "react";
import { InputNumber, Icon, Tooltip } from "antd";
import { connect } from "react-redux";
import { setField, updateProgress } from "../../action_creators";
import PhotoRequired from "./PhotoRequired";
import { fieldHasErrors } from "../../utilities";

const stop = (state, ownProps) => ({ field: state.fields[ownProps.id] });
const dtop = (dispatch) => ({
  setField: ({ id, value }) => {
    dispatch(setField(id, value));
    dispatch(updateProgress());
  },
});

function NumericInput({
  setField,
  field,
  id,
  question,
  label,
  required,
  tipText,
  value = 0,
  photoRequired,
}) {
  // Set default field properties if not defined
  const {
    allowNegatives = false,
    allowDecimals = false,
    lowerLimit = null,
    upperLimit = null,
    digitsAllowed = null,
  } = field || {};

  const validateValue = (number) => {
    const numStr = number.toString();

    // Validate range if lowerLimit and upperLimit are set
    if (lowerLimit !== null && upperLimit !== null && lowerLimit > upperLimit) {
      return `Invalid range: Lower limit (${lowerLimit}) cannot be greater than upper limit (${upperLimit}).`;
    }

    // Validate negative numbers if not allowed
    if (!allowNegatives && number < 0) {
      return `Negative numbers are not allowed.`;
    }

    // Validate decimals if not allowed
    if (!allowDecimals && numStr.includes(".")) {
      return `Decimals are not allowed.`;
    }

    // Validate number of digits after the decimal point if allowDecimals is true
    if (allowDecimals && numStr.includes(".")) {
      const digitsAfterDecimal = numStr.split(".")[1].length;
      if (digitsAllowed && digitsAfterDecimal > digitsAllowed) {
        return `Number can have at most ${digitsAllowed} digits after the decimal.`;
      }
    }

    // Validate total digits if allowDecimals is false
    if (!allowDecimals && digitsAllowed && numStr.length > digitsAllowed) {
      return `Number can have at most ${digitsAllowed} digits.`;
    }

    // Validate if the number is within the lower and upper limit
    if (lowerLimit !== null && number < lowerLimit) {
      return `Number should be greater than or equal to ${lowerLimit}.`;
    }
    if (upperLimit !== null && number > upperLimit) {
      return `Number should be less than or equal to ${upperLimit}.`;
    }

    return null; // No errors
  };

  const handleChange = (number) => {
    const errorMessage = validateValue(number);
    setField({ id, value: { ...field, value: number, errorMessage } });
  };

  const checkRangeValidity = () => {
    if (lowerLimit !== null && upperLimit !== null && lowerLimit > upperLimit) {
      return `Invalid range: Lower limit (${lowerLimit}) cannot be greater than upper limit (${upperLimit}).`;
    }
    return null;
  };

  const rangeErrorMessage = checkRangeValidity();

  return (
    <div className="question-container">
      <div className="bold padding-bottom-1 linebreak question-title">
        {question}
        {required && (
          <Tooltip title="Required">
            <Icon
              type="exclamation-circle"
              style={{ fontSize: "1.5rem", padding: "0 0 0 0.5rem", color: "red" }}
            />{" "}
            <span style={{ fontSize: "1.25rem", color: "red" }}> Required</span>
          </Tooltip>
        )}
      </div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{ fontWeight: 600 }}>
          {(label || "").toUpperCase()}
        </div>
        <div
          className="full-width"
          style={{
            border:
              fieldHasErrors(field) || field.errorMessage || rangeErrorMessage
                ? "1px solid red"
                : "initial",
          }}
        >
          <InputNumber
            size="large"
            onChange={handleChange}
            value={value}
            style={{ width: "100%" }}
          />
        </div>
        {field.errorMessage && !rangeErrorMessage && (
          <div className="error-message" style={{ color: "red" }}>
            {field.errorMessage}
          </div>
        )}
        {rangeErrorMessage && (
          <div className="error-message" style={{ color: "red" }}>
            {rangeErrorMessage}
          </div>
        )}
        <div className="linebreak">
          <medium>
            {tipText && `(${tipText})`}
            {allowNegatives ? " Negatives allowed." : " Negatives not allowed."}
            {allowDecimals ? " Decimals allowed." : " Decimals not allowed."}
            {digitsAllowed ? ` Max ${digitsAllowed} digits.` : ""}
            {lowerLimit !== null ? ` Min: ${lowerLimit}.` : ""}
            {upperLimit !== null ? ` Max: ${upperLimit}.` : ""}
          </medium>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && (
        <PhotoRequired />
      )}
    </div>
  );
}

const WrappedNumericInput = connect(stop, dtop)(NumericInput);
export default WrappedNumericInput;
