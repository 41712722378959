import {firebase} from '../../utilities'
import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import {generateProjectShell, setInspector} from '.'
import { db } from '../../utilities';

const projects = new DB.Projects()

export default async function assembleProject(args) {
  const {organization, client, installer = null, inspector = null, restrict_schedule, formRubrics} = args
  const {site, rubric = null, projectType, projRef, project = {intake: {}, inspection: {}}} = args
  const { allowCreateProject= false} =args
  let returns = []
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()
  let pp = {organization, client, installer, rubric, site, updated: timestamp, restrict_schedule, formRubrics}
  

  if (!client || !Object.keys(pp.client).length) {
    pp.client = {...auth.sunkaizenUser.organization, qualifications: {}}
  }
  if (!project.id) {
    pp = {...pp, ...{id: projRef.id, assigned: false, created:timestamp, updated: timestamp}}
  }

  try {
    if (organization.id === auth.sunkaizenUser.organization.id) {
      if (projectType.title !== project.intake.title) {
        pp.intake = await generateProjectShell(projectType)
        pp.status = pp.intake?.noSchedule ? `In Progress` : `To Be Scheduled`
      }
    } else {
      pp.status = 'Requested'
    }
    if(pp.formRubrics === undefined || pp.formRubrics === null) {
      pp.formRubrics = {}
    }

    Object.keys(pp.rubric.questions).forEach(rec => {
      if (pp.rubric.questions[rec] && pp.rubric.questions[rec].OptionActions) {
        const quest1 = pp.rubric.questions[rec].OptionActions
        Object.keys(quest1).forEach(oAct => {
          quest1[oAct].forEach((testrec, index) => {
            if (testrec && testrec.id) {
              const {id, conditionGroup, actionIndex, strategy} = testrec
              pp.rubric.questions[rec].OptionActions[oAct][index] = 
              { id, conditionGroup: conditionGroup || {}, actionIndex, strategy }
            }
          })
        })
      }
    })

    await setInspector(project, inspector, returns)
    // here i am adding 2 new fields to the project object - bravo  DOE1-516
    pp = {...project, ...pp, updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name }
    //Projects with allow inspector to create projects 
    if(pp.created !== pp.updated){
      pp={...pp, allowInspectorToCreateProjects: allowCreateProject}
    }
    await projects.update(pp.id, null, {id: pp.id});
    await db.collection('projects').doc(pp.id).update({...pp});

    // await projects.updatebatch(pp.id, null, pp)
  } catch (e) {
    console.log(`error in assembleProject`, e)
  }
}
